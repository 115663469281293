body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#inquiriesList .MuiTable-root td p {
    font-size: 0.7rem !important;
}
#inquiriesList .MuiTable-root th span {
    font-size: 0.7rem !important;
}
#inquiriesList .MuiTable-root td, #inquiriesList .MuiTable-root th, #inquiriesList {
    font-size: 0.7rem !important;
    position: inherit !important;    
    right: initial !important;
}

#inquiriesList td:first-child, #inquiriesList th:first-child{
    padding-left: 1rem !important; 
} 

#inquiriesList .MuiTableCell-head{
    padding-left: 0 !important; 
    padding-right: 0.5rem !important;
} 

#inquiriesList .MuiTableCell-body{
    padding: 0 0 0 0 !important; 
} 

.MuiGrid-grid-lg-2{
    display: flex;
    position: relative;
}
